import 'core-js/stable'
import "regenerator-runtime";
import Vue from 'vue'
import './shared/local-storage';
import './shared/filtros';
import CoreuiVue from '@coreui/vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import store from './store'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import Axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuelidate from 'vuelidate';
import Loader from './views/Componentes/loader.vue';
import Plotly from 'plotly.js';
import locale from 'plotly.js-locales/pt-br';
Plotly.register(locale);
Plotly.setPlotConfig({ locale: 'pt-BR', displaylogo: false, responsive: true });

Axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.use(CoreuiVue)
Vue.component("Loader", Loader);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.prototype.$eventoHub = new Vue();
new Vue({
  el: '#app',
  router,
  store,
  icons,

  template: '<App/>',
  components: {
    App
  },
  mounted () {
    let modoEscuro = this.$localStorage.get("modo_escuro", false);
    this.$store.commit("set", ["darkMode", modoEscuro]);
    let autorizacao = this.$sessionStorage.get("autorizacao", false);
    Axios.defaults.headers.common["Authorization"] = autorizacao ? autorizacao.token : false;
  }

})
