import Vue from "vue";

Vue.filter('inteiro', function (value) {
  return Number.isNaN(Number.parseInt(value)) ? 0 : Number.parseInt(value);
});
Vue.filter('decimal', function (value) {
  return Number.isNaN(Number.parseFloat(value)) ? 0 : parseFloat(value);
});
Vue.filter('precisao', function (value, precisao) {
  return Number.isNaN(Number.parseFloat(value)) ? 0 : parseFloat(value).toFixed(precisao);
})
