import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Dados = () => import('@/views/Dados/Dados')


const Sondagem = () => import('@/views/Sondagem/Sondagem')

const Pilar = () => import('@/views/Pilar/Pilar')

const Criterio = () => import('@/views/Criterios/Criterio')

const Geotecnico = () => import('@/views/Geotecnico/Geotecnico')

const Estrutural = () => import('@/views/Estrutural/Estrutural')

const EstruturalSapata = () => import('@/views/Estrutural/Sapatas/Sapatas')

const EstruturalBloco = () => import('@/views/Estrutural/Blocos/Blocos')

const EstruturalTubulao = () => import('@/views/Estrutural/Tubulao/Tubulao')

const Login = () => import('@/views/pages/Login')

const EstruturaVisualizacao = () => import("@/views/Estrutural/EstruturaVisualizacao")



Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/',
          name: 'Projetos',
          component: Dashboard
        },
        {
          path: 'projeto/:projetoID',
          redirect: 'projeto/:projetoID/dados',
          name: 'Projeto',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'dados',
              name: 'Dados',
              component: Dados
            },
            {
              path: 'sondagem',
              name: 'Sondagem',
              component: Sondagem
            },
            {
              path: 'pilar',
              name: 'Pilar',
              component: Pilar
            },
            {
              path: 'criterio',
              name: 'Criterio',
              component: Criterio
            },
            {
              path: 'geotecnico',
              name: 'Geotenico',
              component: Geotecnico
            },
            {
              path: 'estrutural',
              name: 'Estrutural',
              component: Estrutural
            },
            {
              path: 'estrutural/:estruturalID',
              redirect: 'estrutural',
              name: 'Edição Estrutural',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'sapata',
                  name: "Sapata",
                  component: EstruturalSapata
                },
                {
                  path: 'bloco',
                  name: "Estacas",
                  component: EstruturalBloco
                },
                {
                  path: 'tubulao',
                  name: 'Tubulão',
                  component: EstruturalTubulao
                }
              ]
            }
          ]
        }]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Login
    },
    {
      path: '/teste',
      name: 'Teste',
      component: EstruturaVisualizacao
    },
  ]
});
router.beforeEach((to, from, next) => {
  let autorizacao = Vue.$sessionStorage.get("autorizacao", false);
  if (to.name !== 'Login' && !autorizacao)
    next({ name: 'Login' })
  else
    next();
});

export default router;
