<template>
  <div class="overlay d-flex align-items-center justify-content-center flex-row" v-if="loading">
    <CSpinner style="width:4rem;height:4rem;" color="danger" grow />
    <p>{{texto}}</p>
  </div>
</template>
<script>
module.exports = {
  name: "Loader",
  data: function() {
    return {};
  },
  props: {
    loading: Boolean,
    texto: {
      type: String,
      default: "Carregando..."
    }
  }
};
</script>
<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
}
.overlay p {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  font-size: 20px;
}
</style>
